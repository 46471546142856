import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../components/LayoutLanding'
import { Links } from '../components/Links'
import { LandingTopbar } from '../components/LandingTopbar'
import { SEO } from '../components/SEO'

const Fees = () => (
  <Layout>
    <SEO
      title="Vanna | Tarifas"
      tags={[
        'Infraestrutura',
        'Split',
        'Pagamentos instanâneos',
        'Pix',
        'Receba em segundos',
      ]}
      canonical="https://vanna.app/tarifas/"
    />
    <LandingTopbar />
    <Links />
    <section className="relative pb-8 mt-16 px-5 md:px-8 lg:px-16">
      <div
        className="absolute -bottom-48 lg:left-2/4 w-full max-w-4xl"
        style={{ zIndex: -10 }}
      >
        <StaticImage
          src="../images/tar.png"
          placeholder="tracedSVG"
          layout="fullWidth"
          quality={100}
          alt="Tarifas"
        />
      </div>
      <div className="md:max-w-3xl md:mx-auto">
        <h1 className="text-gray-900 text-3xl leading-9 font-heading md:text-center font-medium lg:text-4xl">
          Tarifas simples para seu negócio{' '}
          <span className="bg-purple-brand text-white text-bold px-1">
            lucrar mais
          </span>
          .
        </h1>
        <p className="my-4 text-gray-800 leading-6 sm:text-xl lg:text-lg xl:text-xl">
          Aqui as cobranças são fixas, nada de porcentagens. Não temos a
          intenção de ser sócio de seu negócio. Nosso foco é oferecer as
          melhores ferramentas para cobrança instantâneas do mercado. Caso tenha
          volumes astronômicos ou queira negociar, entre em contato!
        </p>
        <div className="flex justify-center">
          <img className="h-12 w-auto" src="/images/logo-s.png" alt="Logo" />
        </div>
      </div>
      <div className="flex flex-row mx-auto my-10 justify-center">
        <div
          className="rounded-lg  bg-bit-transparent max-w-xs"
          style={{
            borderRadius: '0.63rem',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0.19rem 0.75rem 0px',
            minWidth: '300px',
          }}
        >
          <p className="text-2xl font-apercu leading-normal text-center font-bold text-black pt-4">
            Sem surpresas
          </p>{' '}
          <p className="text-4xl font-inter leading-normal text-center font-bold text-black pb-4">
            <span className="font-inter text-base leading-loose text-center font-medium text-black uppercase">
              R${' '}
            </span>
            0,00
            <span className="text-sm font-inter leading-tight text-center font-bold text-black opacity-50">
              {' '}
              /mensalidade
            </span>
          </p>
          <ul>
            <li className="text-xs font-inter leading-normal text-center font-medium text-black py-4 border-t border-gray-300">
              <p className="text-2xl font-inter leading-normal text-center font-bold text-black">
                <span className="font-inter text-base leading-loose text-center font-medium text-black uppercase">
                  R${' '}
                </span>
                1,99
                <span className="text-sm font-inter leading-tight text-center font-bold text-black opacity-50">
                  {' '}
                  /recebimento Pix
                </span>
              </p>
            </li>
            <li className="text-xs font-inter leading-normal text-center font-medium text-black py-4 border-t border-gray-300">
              <p className="text-2xl font-inter leading-normal text-center font-bold text-black">
                <span className="font-inter text-base leading-loose text-center font-medium text-black uppercase">
                  R${' '}
                </span>
                0,50
                <span className="text-sm font-inter leading-tight text-center font-bold text-black opacity-50">
                  {' '}
                  /transferência Pix
                </span>
              </p>
            </li>
            <li className="text-xs font-inter leading-normal text-center font-medium text-black py-4 border-t border-gray-300">
              <p className="text-2xl font-inter leading-normal text-center font-bold text-black">
                <span className="font-inter text-base leading-loose text-center font-medium text-black uppercase">
                  R${' '}
                </span>
                0,20
                <span className="text-xs font-inter leading-tight text-center font-bold text-black opacity-50">
                  {' '}
                  /cobrança não paga
                </span>
              </p>
            </li>
          </ul>
          <div className="pt-4 text-center">
            <div className="rounded-md shadow-md">
              <OutboundLink
                target="_blank"
                rel="noopener"
                href="https://api.whatsapp.com/send/?phone=5515981779550&text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&app_absent=0"
                className="bg-yellow-brand hover:bg-gray-900 hover:text-white flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md text-purple-brand md:text-lg"
              >
                Quero saber mais
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Fees
